import axios from 'axios';
import { helper } from 'utils';
import history from './history';

export const config = {
  env: helper.config.env,

  live: helper.config.api.live,
  local: helper.config.api.local,
  staging: helper.config.api.staging,
};

(function () {
  var token = sessionStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    axios.defaults.headers.common['Authorization'] = '';
  }
})();

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use((response) => {
  const logg = helper.getStorageJson('userData');
  if (response?.data?.status === 505 && logg.uuid) {
    localStorage.clear();
    sessionStorage.clear();
    helper.setStorage('expired', 'yah');
    history.push('/');
    history.go();
    return;
  } else {
    return response;
  }
},
  (error) => {
    // Do something with response error
    if (error?.response?.data?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      helper.setStorage('expired', 'yah');
      history.push('/');
      history.go();
    }
    return Promise.reject(error);
  }
);
