import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from 'utils/history';
import { axius, helper } from 'utils';
import { connect } from 'react-redux';
import { handleIsUserAuthenticated } from 'store/_actions/auth';
import * as Action from 'store/_actions';
import { io } from 'socket.io-client';
import NotificationAudio from 'components/NotificationAudio';


// ::: layouts
const Navbar = React.lazy(() => import('components/Navbar'));
const AuthRoute = React.lazy(() => import('containers/AuthRoute'));
const PrivateRoute = React.lazy(() => import('containers/PrivateRoute'));

const Terms = React.lazy(() => import('pages/Terms'));
const LogIn = React.lazy(() => import('pages/auth/LogIn'));
const Privacy = React.lazy(() => import('pages/Privacy'));
const Page404 = React.lazy(() => import('pages/static/Page404'));
const Register = React.lazy(() => import('pages/auth/Register'));
const DashboardPage = React.lazy(() => import('./Main'));
const ResetPassword = React.lazy(() => import('pages/auth/ResetPassword'));
const ForgotPassword = React.lazy(() => import('pages/auth/ForgotPassword'));


const App = (props) => {
  const { _auth: { userData } } = props;

  const [played, setPlayed] = React.useState(true);

  React.useEffect(() => {
    if (helper.isLocal && helper.isStaging) {
      const socket = io(helper.config.io[helper.config.env], {
        timeout: 20000,
        transports: ['websocket'],
        reconnection: true, // enable automatic reconnection
        reconnectionDelay: 1000, // start with 1 second delay
        reconnectionDelayMax: 5000, // max delay is 5 secs
        reconnectionAttempts: Infinity, // retry forever
      });
      socket.on('new_order', (data) => {
        console.log('new_IO_order', data);
        // Only play sound alert for venues user has access to
        if (data.merchant === 'CR' && (userData.venues || []).map(ven => ven.uuid).includes(data.order?.store || data.order?.venue?.uuid)) {
          props.dispatch(Action.setNewOrder(data));
          helper.playNotificationSound(9000).then(e => {
            setPlayed(e);
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if (sessionStorage.getItem('token')) {
      props.dispatch(Action.getUserData());
      props.dispatch(handleIsUserAuthenticated(true));
    } else {
      props.dispatch(handleIsUserAuthenticated(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem('token')]);

  React.useEffect(() => {
    Promise.all([
      axius.get('settings'),
      // axius.get(`plans`, { status: 1 }),
    ]).then(res => {
      res[0].status === 200 && helper.setStorageJson('settings', res[0].data);
      res[0].status === 200 && helper.setStorageJson('permissions', res[0].data.permissions);
      res[0].status === 200 && props.dispatch(Action.changeOutletData('locations', res[0].data.countries.sort((a, b) => (a.name > b.name) ? 1 : -1)));
      res[0].status === 200 && props.dispatch(Action.changeOutletData('roles', res[0].data.roles.sort((a, b) => (a.name > b.name) ? 1 : -1)));
      // res[1].status === 200 && props.dispatch(Action.changeOutletData('plans', res[1].data.sort((a, b) => (a.name > b.name) ? 1 : -1)));

      if (!helper.isLocal && helper.versionBreak(res[0].data?.version?.desktop) > helper.versionBreak(helper.app.version)) {
        console.log(`new Version: ${res[0].data.version.desktop}`);
        caches.keys().then(keys => {
          Promise.all(keys.map(key => caches.delete(key))).then(() => {
            window.history.forward(1);
            window.location.reload(true);
          });
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      {userData && userData.id && (<Navbar />)}

      <Switch>
        <AuthRoute path="/" name="login" exact component={LogIn} />
        <AuthRoute path="/admin" name="login" exact component={LogIn} />
        <AuthRoute path="/forgot-password" name="forgot-password" exact component={ForgotPassword} />
        <AuthRoute path="/collborator/signup" name="register" exact component={Register} />

        <Route exact path="/termspolicy" component={Terms} />
        <Route exact path="/privacypolicy" component={Privacy} />

        <Route exact path="/reset" component={ResetPassword} />
        <PrivateRoute path="/" component={DashboardPage} />

        <Route component={Page404} />
      </Switch>

      <NotificationAudio open={!played} />
    </Router>
  );
}

function mapStateToProps(state) {
  return { _auth: state.auth };
}

export default connect(mapStateToProps)(App);
