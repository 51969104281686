import * as ActionTypes from 'constants/ActionTypes';
import { helper } from 'utils';

const initialState = {
  isFetching: null,
  locationsError: null,
  outlets: [],
  outlet: {},
  success: false,
  message: null,
  error: null,

  users: helper.getStorageJson('users') || {
    data: { all: [], expired: [], silent: [], banks: [] },
    counts: { all: 0, expired: 0, silent: 0, banks: 0 },
  },
  drinks: helper.getStorageJson('drinks') || [],
  venues: helper.getStorageJson('venues') || [],
  tags: helper.getStorageJson('tags') || [],
  roles: helper.getStorageJson('roles') || [],
  plans: helper.getStorageJson('plans') || [],
  o_filter: helper.getStorageJson('o_filter') || { orderBy: 'newest' },
  settings: helper.getStorageJson('settings') || {},
  cuisines: helper.getStorageJson('cuisines') || [],
  locations: helper.getStorageJson('locations') || [],
  categories: helper.getStorageJson('categories') || [],
  outletvenues: helper.getStorageJson('outletvenues') || [],
  menu_categories: helper.getStorageJson('menu_categories') || [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_LOCATION_SUCCESS:
      return {
        ...state,
        locations: action.payload,
      };

    case ActionTypes.GET_LOCATION_ERROR:
      return {
        ...state,
        locationsError: action.payload,
      };

    case ActionTypes.RECEIVE_USER_OUTLETS:
      return {
        ...state,
        outlets: action.payload,
        success: false,
        message: null,
        isFetching: false,
      };

    case ActionTypes.GET_SINGLE_OUTLET:
      return {
        ...state,
        outlet: action.payload,
        isFetching: false,
      };

    case ActionTypes.INIVTE_COLLAB_RESPONSE:
      return {
        ...state,
        message: action.payload,
        isFetching: false,
      };

    case ActionTypes.ADD_MENU_RESPONSE:
      return {
        ...state,
        message: action.payload,
        isFetching: false,
      };

    case ActionTypes.UPDATE_OUTLET_RESPONSE:
      return {
        ...state,
        message: action.payload,
        isFetching: false,
      };

    case ActionTypes.FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypes.FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypes.INIVTE_COLLAB_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.UPDATE_OUTLET_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case ActionTypes.RESET_RESPONSE:
      return {
        ...state,
        error: null,
        isFetching: false,
        message: null,
      };

    case ActionTypes.CHANGE_OUTLET_DATA:
      return {
        ...state,
        [action.key]: action.data,
      };

    case ActionTypes.NEW_ORDER:
      return {
        ...state,
        newOrder: action.data,
      };

    default:
      return state;
  }
}
