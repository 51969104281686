// Auth Actions Types
export const RECEIVE_USER_DATA = "RECEIVE_USER_DATA";
export const HANDLE_LOGIN_REQUEST = "HANDLE_LOGIN_REQUEST";
export const HANDLE_LOGIN_SUCCESS = "HANDLE_LOGIN_SUCCESS";
export const HANDLE_LOGIN_ERROR = "HANDLE_LOGIN_ERROR";
export const HANDLE_EMAIL_SUCCESS = "HANDLE_EMAIL_SUCCESS";
export const HANDLE_EMAIL_ERROR = "HANDLE_EMAIL_ERROR";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR";
export const COLLABORATOR_SIGNUP_SUCCESS = "COLLABORATOR_SIGNUP_SUCCESS";
export const HANDLE_REGISTER_ERROR = "HANDLE_REGISTER_ERROR";
export const HANDLE_IS_USER_AUTHENTICATED = "HANDLE_IS_USER_AUTHENTICATED";
export const UPDATE_USER_RESPONSE = "UPDATE_USER_RESPONSE";
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE";
export const RESET_UPDATE_RESPONSE = "RESET_UPDATE_RESPONSE";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const SET_USER_LIMIT = "SET_USER_LIMIT";
export const SELECTED_USER = "SELECTED_USER";
// Forgot Password
export const RECEIVE_PASSWORD_RESET_TOKEN = "RECEIVE_PASSWORD_RESET_TOKEN";
export const RECEIVE_PASSWORD_RESET_TOKEN_ERROR =
  "RECEIVE_PASSWORD_RESET_TOKEN_ERROR";
export const RESET_PASSWORD_TOKEN = "RESET_PASSWORD_TOKEN";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const FORGOT_PASSWORD_TOGGLE = "FORGOT_PASSWORD_TOGGLE";

// Outlets
export const RECEIVE_USER_OUTLETS = "RECEIVE_USER_OUTLETS";
export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_ERROR = "FETCH_ERROR";
export const INIVTE_COLLAB_RESPONSE = "INIVTE_COLLAB_RESPONSE";
export const ADD_MENU_RESPONSE = "ADD_MENU_RESPONSE";
export const UPDATE_OUTLET_RESPONSE = "UPDATE_OUTLET_RESPONSE";
export const UPDATE_OUTLET_ERROR = "UPDATE_OUTLET_ERROR";
export const GET_SINGLE_OUTLET = "GET_SINGLE_OUTLET";
export const INIVTE_COLLAB_ERROR = "INIVTE_COLLAB_ERROR";
export const CHANGE_OUTLET_DATA = "CHANGE_OUTLET_DATA";

// Events
export const RECEIVE_USER_EVENTS = "RECEIVE_USER_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const GET_SINGLE_EVENT = "GET_SINGLE_EVENT";
export const UPDATE_EVENT_RESPONSE = "UPDATE_EVENT_RESPONSE";
export const RESET_RESPONSE = "RESET_RESPONSE";

export const NEW_ORDER = 'NEW_ORDER';