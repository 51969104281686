import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import { helper } from 'utils';
import HttpsRedirect from 'react-https-redirect';
import Loading from 'components/Loading';
import App from './containers/App';

// sentry / datadog
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

// Style Sheets
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/stylesheets/main.scss';
import './index.less';


!helper.isLocal && process.env.REACT_APP_SENTRY_DSN && Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `cr-desktop-${process.env.REACT_APP_ENV}@${require('../package.json').version}`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
    }),
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

!helper.isLocal && datadogRum.init({
  applicationId: 'c03ed0c4-d729-47a9-b92b-35232f18fff8',
  clientToken: 'pub9d95fc23a8f3b4bb129739d82f3df9a0',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'us5.datadoghq.com',
  // service: 'desktop',
  service: (process.env.REACT_APP_ENV === 'staging' ? 'cr-desktop' : 'chr-desktop'),
  env: process.env.REACT_APP_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

!helper.isLocal && datadogLogs.init({
  clientToken: 'pub9d95fc23a8f3b4bb129739d82f3df9a0',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true, // Automatically send errors as logs sampleRate: 100, // Percentage of sessions to send logs service: 'your-application-name',
  env: process.env.REACT_APP_ENV,
});

// Creating REDUX store with the required configurations
const store = configureStore();

ReactDOM.render(
  <React.Suspense fallback={<div><Loading textSecondary={true} /></div>}>
    <HttpsRedirect>
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={"An error has occurred"}>
          <App />
        </Sentry.ErrorBoundary>
      </Provider>
    </HttpsRedirect>
  </React.Suspense>,
  document.getElementById("root")
);


// custom service worker
if (!helper.isLocal && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register(`sw.js?${Date.now()}`)
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}