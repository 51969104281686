import GlovoIcon from 'assets/img/glovo.png';
import TomatiIcon from 'assets/img/logo.png';
import ChowdeckIcon from 'assets/img/chowdeck.png';

export const Providers = [
    { code: 'glovo', label: 'Glovo', image: GlovoIcon, },
    { code: 'tomati', label: 'Chicken R.', image: TomatiIcon, },
    { code: 'chowdeck', label: 'ChowDeck', image: ChowdeckIcon, },
];

export const PromotionTypes = [
    // { code: 'CASHBACK', label: 'Cashback' },
    { code: 'FLAT_OFF_ITEM', label: 'Flat Discount Off Item' },
    { code: 'FLAT_OFF_CATEGORY', label: 'Flat Discount Off Category' },
    { code: 'PERCENTAGE_OFF_ITEM', label: 'Percentage Off Item' },
    { code: 'PERCENTAGE_OFF_CATEGORY', label: 'Percentage Off Category' },
];