import * as ActionTypes from 'constants/ActionTypes';
import { helper } from 'utils';

export function updateOutletError(message) {
  return {
    type: ActionTypes.UPDATE_OUTLET_ERROR,
    payload: message,
  };
}

export function resetOutletResponse() {
  return {
    type: ActionTypes.RESET_RESPONSE,
  };
}


export function changeOutletData(key, data) {
  key !== 'outletvenuess' && helper.setStorageJson(key, data);
  return {
    key,
    type: ActionTypes.CHANGE_OUTLET_DATA,
    data,
  };
}

export function setNewOrder(data) {
  return {
    type: ActionTypes.NEW_ORDER,
    data,
  };
}
